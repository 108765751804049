<template>
  <Page :title="$t('target.title')" :classes="['page--static']">
    <div v-html="$t('target.content.paragraph_1')"></div>
    <br>
    <div>
      <ul>
        <li v-html="$t('target.content.type_1')"></li>
        <li v-html="$t('target.content.type_2')"></li>
        <li v-html="$t('target.content.type_3')"></li>
        <li v-html="$t('target.content.type_4')"></li>
      </ul>
    </div>
    <Button
      :classes="['btn--more']"
      @onclick="$router.push({name: 'register', params: {locale: $i18n.locale } })">
      {{$t('common.start_now')}}
    </Button>
    <br><br>
    <div v-html="$t('target.content.contact')"></div>
  </Page>
</template>

<script>
export default {
  name: 'TargetPage',
};
</script>

<style lang="scss" scoped>
</style>
